import { useEffect, useState } from 'react';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import useModifierById from 'hooks/useModifierById';
import { onWheelPreventChangeNumberField } from 'utils/menu';
import { ModifierOptionName, ModifiersGroupContainer, ModifierTitle } from './styles';
import { Checkbox, Divider, FormControlLabel, Grid, Radio, TextField } from '@mui/material';

const Modifiers = ({ type = '', formMethods, modId, preselectOptions }) => {
  const { t } = useTranslation();
  const { setValue } = formMethods;
  const { currentModifier, loadingModOpt } = useModifierById(modId);
  const [stickyModifier, setStickyModifier] = useState({
    modId: '',
    sticky: false,
  });

  const deleteOptions = (preselected, currentOption) => {
    delete currentOption.count;
    if (preselected.options[modId]?.length) {
      const findIndex = preselected.options[modId]?.findIndex((opt) => opt._id === currentOption._id);
      if (findIndex !== -1) {
        preselected.options[modId].splice(findIndex, 1);
      }
    }
    setValue('preselectOptions', preselected, { shouldDirty: true });
  };

  const handleChangeInput = (e, option) => {
    const { value } = e.target;
    option.count = Number(value);
    if (value) {
      if (!preselectOptions?.options?.[modId]) {
        preselectOptions.options = {
          ...preselectOptions.options,
          [modId]: [option],
        };
      } else {
        if (preselectOptions?.options?.[modId]) {
          preselectOptions?.options?.[modId]?.forEach((opt, index) => {
            if (opt._id === option._id) {
              preselectOptions.options[modId][index] = option;
            }
          });
          const findOption = preselectOptions?.options?.[modId]?.find((opt) => opt._id === option._id);
          if (!findOption) {
            preselectOptions.options = {
              ...preselectOptions.options,
              [modId]: [...preselectOptions.options[modId], option],
            };
          }
        }
      }

      if (modId === stickyModifier.modId) {
        const updatedOptions = preselectOptions?.options[modId].map((option) => ({
          ...option,
          sticky: Boolean(option?.count > 0),
        }));
        preselectOptions.options = {
          ...preselectOptions.options,
          [modId]: [...updatedOptions],
        };
      }
      setValue('preselectOptions', preselectOptions, { shouldDirty: true });
    } else {
      deleteOptions(preselectOptions, option);
    }
  };

  const handleChangeCheckBox = (e, option) => {
    const { checked } = e.target;
    if (checked) {
      option.count = 1;
      if (!preselectOptions?.options?.[modId]) {
        preselectOptions.options = {
          ...preselectOptions.options,
          [modId]: [option],
        };
      } else {
        preselectOptions.options = {
          ...preselectOptions.options,
          [modId]: [...preselectOptions.options[modId], option],
        };
      }
      if (modId === stickyModifier.modId) {
        const updatedOptions = preselectOptions?.options[modId].map((option) => ({
          ...option,
          sticky: checked,
        }));
        preselectOptions.options = {
          ...preselectOptions.options,
          [modId]: [...updatedOptions],
        };
      }
      setValue('preselectOptions', preselectOptions, { shouldDirty: true });
    } else {
      deleteOptions(preselectOptions, option);
    }
  };

  const handleChangeRadio = (e, option) => {
    const copyOption = { ...option };
    if (!preselectOptions?.options?.[modId]) {
      Object.assign(copyOption, { tags: ['size'], count: 1 });
      preselectOptions.options = {
        ...preselectOptions.options,
        [modId]: [copyOption],
      };
    } else {
      deleteOptions(preselectOptions, copyOption);
      Object.assign(copyOption, { tags: ['size'], count: 1 });
      preselectOptions.options = {
        ...preselectOptions.options,
        [modId]: [copyOption],
      };
    }
    if (modId === stickyModifier?.modId) {
      const updatedOptions = preselectOptions?.options[modId].map((option) => ({
        ...option,
        sticky: true,
      }));
      preselectOptions.options = {
        ...preselectOptions.options,
        [modId]: [...updatedOptions],
      };
    }
    setValue('preselectOptions', preselectOptions, { shouldDirty: true });
  };

  const handleChangeSticky = (event, modifierId) => {
    if (currentModifier?.id === modifierId && event.target.checked) {
      setStickyModifier({
        modId: modifierId,
        sticky: event.target.checked,
      });
    } else {
      setStickyModifier({
        modId: '',
        sticky: false,
      });
    }
    if (preselectOptions?.options[modifierId]) {
      const updatedOptions = preselectOptions.options[modifierId].map((option) => ({
        ...option,
        sticky: event.target.checked,
      }));
      preselectOptions.options = {
        ...preselectOptions.options,
        [modifierId]: [...updatedOptions],
      };
      setValue('preselectOptions', preselectOptions, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (preselectOptions?.options && preselectOptions?.options[modId] && modId) {
      preselectOptions?.options[modId]?.forEach((option) => {
        if (option?.hasOwnProperty('sticky') && option?.sticky) {
          setStickyModifier({
            modId: modId,
            sticky: option?.sticky || false,
          });
        }
      });
    }
  }, []);

  if (loadingModOpt) {
    return <Preloader />;
  }

  return (
    <ModifiersGroupContainer>
      <ModifierTitle>{currentModifier?.name?.toUpperCase()}</ModifierTitle>
      {currentModifier?.list?.map((option) => {
        const currentOption = preselectOptions?.options?.[modId]?.find((opt) => opt?._id === option?._id);

        return (
          <>
            <Grid item key={option?._id} display="flex">
              <Grid item xs={8}>
                <ModifierOptionName>{option?.name}</ModifierOptionName>
              </Grid>
              <Grid
                sx={{ label: { m: 0, span: { p: 0 } } }}
                item
                xs={4}
                justifyContent="end"
                display="flex"
                alignItems="center"
              >
                {type === 'size' ? (
                  <FormControlLabel
                    inputProps={{ type: 'number' }}
                    name={`checked-${option._id}`}
                    onChange={(e) => handleChangeRadio(e, option)}
                    onClick={() => {
                      preselectOptions?.options?.[modId]?.forEach((item) => {
                        if (item && item?._id === option?._id) {
                          deleteOptions(preselectOptions, option);
                        }
                      });
                    }}
                    label={''}
                    checked={!!currentOption}
                    control={<Radio disabled={!preselectOptions?.defaultSelection} />}
                  />
                ) : currentModifier?.canSelectMultipleOptions ? (
                  <TextField
                    disabled={!preselectOptions?.defaultSelection}
                    size="small"
                    inputProps={{
                      type: 'number',
                      step: 'any',
                    }}
                    InputLabelProps={{ shrink: true }}
                    name={`quantity-${option._id}`}
                    label={t('default_selections_tab.quantity_label')}
                    sx={{
                      opacity: !preselectOptions?.defaultSelection ? 0.6 : 1,
                    }}
                    onChange={(e) => handleChangeInput(e, option)}
                    onWheel={onWheelPreventChangeNumberField}
                    value={currentOption?.count || ''}
                  />
                ) : (
                  <FormControlLabel
                    inputProps={{ type: 'number' }}
                    name={`checked-${option._id}`}
                    onChange={(e) => handleChangeCheckBox(e, option)}
                    label={''}
                    checked={!!currentOption}
                    control={<Checkbox disabled={!preselectOptions?.defaultSelection} />}
                  />
                )}
              </Grid>
            </Grid>
            <Divider />
          </>
        );
      })}
      <FormControlLabel
        control={
          <Checkbox
            disabled={!preselectOptions?.defaultSelection}
            checked={stickyModifier?.sticky}
            onChange={(event) => handleChangeSticky(event, currentModifier?.id)}
          />
        }
        label={t('Set modifier sticky')}
      />
    </ModifiersGroupContainer>
  );
};

export default Modifiers;
